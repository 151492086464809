import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {
 

  return (
    <Layout>
      <SEO title="Home" />
      <section className="promote-banner background-overlay">
        <h1 className="banner-title">PROMOTE YOUR<br />PASSION</h1>
      </section>

      <section className="promote-intro">
        <h2>IT'S TIME TO BE EFFECTIVE</h2>
        <div className="divider"></div>
        <p>
          Whether you’re aiming to pack your bar at non peak hours, sell out your comedy gig, or fill some tickets last minute for your boat party, Yitnow 熱鬧 will connect you to people nearby who want your product.
      </p>

        <div className="img-row">
          <div className="col">
            <h5>Walking Distance</h5>
            <p>
              Yitnow 熱鬧 will only send your promotions to people who have expressed an explicit need for your product within the last 3 hours within walking distance from your location.
		      </p>
            <div className="img-wrapper">
              <img src="/images/promote-1.jpg" alt="drinking, night-out, bars" />
            </div>
          </div>
          <div className="col">
            <h5>No Requirement to Discount</h5>
            <p>
              Unlike with other apps you can fill your off-peak hours without offering crippling 50% discounts.
          </p>
            <p>
              Yitnow 熱鬧 gives you the flexibility to promote your product however you wish, allowing you to compete on the quality of your product.
          </p>
            <p>
              If you do choose to offer discounts as part of your promotion, you can do so in a way that suits you.
          </p>
            <div className="img-wrapper">
              <img src="/images/promote-2.jpg" alt="drinking, night-out, bars" />
            </div>
          </div>
        </div>

        <div className="img-row">
          <div className="col">
            <h5>Promote Anything... Legal</h5>
            <p>From Michellin starred restaurants to garage sales, from plumbing to plum wine, whatever you're promoting you can promote it effectively to the people who want it... just make sure it complies with our
            <a href="/terms-of-use/">Terms of use.</a>
            </p>
            <div className="img-wrapper">
              <img src="/images/promote-3.jpg" alt="beach, ocean, running" />
            </div>
          </div>
          <div className="col">
            <h5>It's as Easy as 1,2,3!!!</h5>
            <p>
              We just have one app, the same great app that you use to meet new people nearby right now, is the same app that you can use to promote your product to new people nearby right now.
          </p>
            <p>
              Just navigate to the campaigns tab, and create a campaign in a single page.
          </p>
            <div className="img-wrapper">
              <img src="/images/create_campaign_original.jpg" alt="Create a campaign" />
            </div>
          </div>
        </div>

      </section>

      <section className="promote-details">
        <div className="img-row">
          <div className="col">
            <h3>
              How to buy Promotions
          </h3>
            <div className="divider"></div>
            <p>
              To send your promotion to people nearby you bid using virtual Gold Coins. People will never be sent more than 5 promotions in any session, so your promotion if successful will be seen.
          </p>
            <p>
              Set a maximum bid and our algorithm will make sure that you never spend more than this amount to reach a user, set a maximum campaign budget and Yitnow 熱鬧 will make sure you never overspend.
          </p>
          </div>
          <div className="col">
            <h3>
              Where to buy Coins
          </h3>
            <div className="divider"></div>
            <p>
              Virtual Gold Coins are available for purchase through the Apple App Store, Google Play Store and this website on login.
          </p>
            <p>
              Coins are priced at HK$0.01 each and are supject to the following additional charges (to cover payment provider fees):
          </p>
            <ul className="dash">
              <li>Apple App Store: 42.86%</li>
              <li>Google Play Store: 42.86%</li>
              <li>This Website: 3.52%+HK$2.35</li>
            </ul>
            <p>
              The App/ Play store fees may seem exorbitant, but they may also provide additional protections.
          </p>
          </div>
        </div>
      </section>
    </Layout>
  )
};

